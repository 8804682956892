<template>
  <div class="">
    <el-card class="box-card">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="头像：" prop="avatar">
          <div class="" style="position: relative">
            <!-- <el-avatar :size="60" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
            <!-- action="https://freemen.work/user/file/upload/" -->
            <el-upload
                action="https://freemen.work/user/file/upload/"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
            >
              <!-- :src="'https://freemen.work/user/file/view/' + imageUrl" -->
              <img
                v-if="imageUrl"
                :src="baseUrl + '/company/file/view/' + imageUrl"
                class="avatar"
              />
              <i class="el-icon-plus" v-else></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item>
          <span class="biaoqian font14" v-if="ruleForm.isAdmin == 1"
            >管理员 - {{ companyName }}</span
          >
          <span class="biaoqian font14" v-else>子账号 - {{ companyName }}</span>
        </el-form-item>

        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入姓名"
            style="width: 500px"
            @change="checkInput"
          >
          </el-input>
          <div style="color: red; font-size: 10px;" v-if="tipsFlag">{{ tips }}</div>
        </el-form-item>
        <el-form-item label="性别:" prop="sex">
          <el-select
            v-model="ruleForm.sex"
            placeholder="请选择性别"
            style="width: 500px"
          >
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            :disabled="true"
            placeholder="请输入手机号"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入邮箱"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
      <el-dialog
        style="padding: 20px"
        class="delete_box"
        :visible.sync="cropperDialogVisible"
        append-to-body
        :close-on-click-modal="false"
        @close="closeUpload"
      >
        <div class="wb-tit font18">图片剪裁</div>
        <div class="cropper-wrap" v-if="option.img">
          <div class="cropper-box">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="option.info"
              :full="option.full"
              :canScale="option.canScale"
              :can-move="option.canMove"
              :can-move-box="option.canMoveBox"
              :fixed="option.fixed"
              :fixed-box="option.fixedBox"
              :original="option.original"
              :auto-crop="option.autoCrop"
              :auto-crop-width="option.autoCropWidth"
              :auto-crop-height="option.autoCropHeight"
              :center-box="option.centerBox"
              :high="option.high"
              :info-true="option.infoTrue"
              :max-img-size="option.maxImageSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              :maxImgSize="option.maxImgSize"
              @realTime="realTime"
            ></vueCropper>
          </div>
          <div class="preview-box">
            <div :style="previewStyle" class="preview-img">
              <div :style="preview.div">
                <img :src="preview.url" :style="preview.img" />
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="finish">确 认</el-button>
          <el-button @click="closeUpload">取 消</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      imageUrl: "",
      ruleForm: {
        avatar: "",
        name: "",
        sex: "",
        phone: "",
        email: "",
        isAdmin: 0,
      },
      cropperDialogVisible: false,
      companyName: "",
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: ['blur', 'change']}],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
      fileList: [],
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
        fileinfo: {},
      },
      fileName: "",
      cropperData: {},
      preview: {},
      previewStyle: {},
      baseUrl: "",
      tips: "",
      tipsFlag: false,
    };
  },
  activated() {
    this.getUserInfo();
    if(this.baseUrl === ""){
      this.baseUrl = axios.defaults.baseURL ? axios.defaults.baseURL : "https://freemen.work";
    }
  },
  watch: {
    cropperOption: {
      handler(value) {
        this.option = Object.assign(this.option, value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //限制输入不超过100个字节数
    checkInput(val) {
      // console.log("inputCheck val = ", val);
      //当前字符串的字节数
      let strlen = 0;
      //用来做字符串截取的下标
      let index = 0;
      //限制的最大输入长度
      let max = 16;

      for (var i = 0; i < val.length; i++) {
        if (strlen > max) {
          //输入长度超标的提示
          this.tips = "名称最大字数不超过8";
          break;
        }
        index = i + 1;
        //判别是中文字符还是英文字符的方法
        if (val.charCodeAt(i) < 27 || val.charCodeAt(i) > 126) { //中文和中文字符
          strlen += 2;
        } else {
          strlen++;
        }
      }
      // 自动截掉多余的字符。保留之前的限制内的字符长度
      if (strlen > max) {
        this.tipsFlag = true;
        this.ruleForm.name = val.substr(0, index - 1);
        setTimeout(() => {
          this.tipsFlag = false;
          this.tips = "";
        }, 2000);
      } else {
        this.tipsFlag = false;
        this.tips = "";
        this.ruleForm.name = val.substr(0, index);
      }
    },

      realTime(preview) {
      // 实时预览
      this.preview = preview;
      this.previewStyle = {
        width: preview.w + "px",
        height: preview.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: this.zoom,
      };
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let len = bstr.length;
      let u8arr = new Uint8Array(len);
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len);
      }
      return new File([u8arr], filename, { type: mime });
    },

    // 关闭上传及裁剪
    closeUpload() {
      this.cropperDialogVisible = false;
    },
    finish() {
      const formData = new FormData();

      this.$refs.cropper.getCropBlob((data) => {
        // let file = new File([data], this.fileName, {type: this.fileName.split('.')[1]});
        // data转换为bolb之后是没有name的,后段接收到file之后需要获取到文件的名称；
        data.name = this.fileName;
        formData.append("file", data, this.fileName);
        this.uploadFIle(formData);
      });
    },
    uploadFIle(formData){
      this.$api.upload("post", formData).then((res) => {
        this.ruleForm.avatar = res.data;
        this.imageUrl = res.data;
        // console.log("uploadFIle pic handle---------response=", res, ", formData=", formData, ", image url=", this.imageUrl);
        this.option.img = "";
        this.cropperDialogVisible = false;
      });
    },
    submitForm(formName) {
      // console.log("person data save, submit form = ", this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            avatar: this.ruleForm.avatar,
            displayName: this.ruleForm.name,
            loginEmail: this.ruleForm.email,
            sex: this.ruleForm.sex,
          };
          this.$api.updateUserInfo("post", config).then((res) => {
            window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$message({
              message: "保存成功",
              type: "success",
            });
          });
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSuccess(response, file) {
      // console.log("upload pic handle---------response=", response, ", file=", file, "URL =", URL.createObjectURL(file.raw));
      this.fileName = file.name;
      this.option.img = URL.createObjectURL(file.raw);
      this.cropperDialogVisible = true;
      this.ruleForm.avatar = response.data;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.avatar = "";
    },

    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        // console.log(res.data.company)
        this.companyName = res.data.company.companyName;

        let userInfo = res.data.companyUser;
        this.ruleForm.avatar = userInfo.avatar;
        if (userInfo.avatar) {
          let obj = {
            url: "https://freemen.work/user/file/view/" + userInfo.avatar,
            // url: "http://192.168.10.174:8081/company/file/view/" + userInfo.avatar,
          };
          this.fileList.push(obj);
        }
        this.imageUrl = userInfo.avatar;
        this.ruleForm.name = userInfo.displayName;
        this.ruleForm.sex = userInfo.sex;
        this.ruleForm.phone = userInfo.phone;
        this.ruleForm.email = userInfo.loginEmail;
        this.ruleForm.isAdmin = userInfo.isAdmin;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-upload {
  .avatar {
    width: 145px;
  }
}
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 30px 30px 80px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.biaoqian {
  position: absolute;
  bottom: 0;
  left: 3px;
  background: #00bcff;
  border: 2px solid #ffffff;
  border-radius: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 0px 4px;
}
.demo-ruleForm {
  width: 700px;
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
    width: 500px;
    margin-top: 50px;
    background: #00bcff;
    border: none;
  }
}

.vueCropper {
  text-align: left;
}
// 截图
.cropper-content {
  .cropper {
    width: calc(100% - 200px);
    height: 340px;
    display: inline-block;
    margin-left: 10%;
    margin-top: 20px;
  }
}
.show-preview {
  float: right;
  width: 140px;
  display: inline-block;
}
.previewImg {
  width: 180px;
  height: 180px;
}
.logoClass /deep/ .el-form-item__content {
  line-height: 18px;
}
.delete_box {
  line-height: 4;
  padding: 30px 0;
  // position: relative;
  // /deep/.el-card__body{
  // 	padding: 50px 30px 30px 30px !important;
  // }
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
    line-height: 1;
  }
  .input-box {
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
  }
  .db-ts {
    text-align: center;
    color: #666666;
    line-height: 1.5;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
    margin-right: 20px;
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
  }
}
</style>




<style lang="less" scoped>
.cropper-dialog {
  & /deep/ .el-dialog {
    width: max-content;
  }
  & /deep/ .el-dialog__body {
    padding: 20px;
  }
  & /deep/ .el-button {
    width: 145px;
  }
}
.cropper-wrap {
  display: flex;
  .cropper-box {
    margin-right: 20px;
    width: 375px;
    height: 176px;
  }
  .preview-box {
    .preview-title {
      display: flex;
      min-width: 100px;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      color: rgba(30, 35, 48, 1);
      font-family: PingFangSC-Medium;
      .preveiw-upload {
        color: #0067ed;
        cursor: pointer;
      }
    }
    .preview-img {
      border-radius: 2px;
    }
  }
}
.fun-btn {
  margin-top: 16px;
  i {
    margin-right: 16px;
    font-size: 18px;
    color: #8c8c8c;
    cursor: pointer;
    &:hover {
      color: #0067ed;
    }
  }
  .reUpload {
    margin-right: 16px;
  }
}
</style>
